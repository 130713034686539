<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/02-4banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">智慧健康体检</div>
            <div class="head-explain-content">个性化、精准化、智能化的健康体检服务</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">解决方案</span></div>
            <div class="head-list-item"><span class="title">方案收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title">个性化</div>
                <div class="purchase-content">根据个人的健康状况、生活习惯等因素，为每个人量身定制适合的体检方案</div>
                <div class="purchase-title">精准化</div>
                <div class="purchase-content">通过大数据分析，对体检结果进行深度挖掘和分析，提前发现潜在的健康问题</div>
                <div class="purchase-title">智能化</div>
                <div class="purchase-content">利用人工智能技术，如机器学习、深度学习等，对体检数据进行智能分析和解读</div>
                <div class="purchase-title">便捷化</div>
                <div class="purchase-content">通过互联网技术，实现线上预约、线下体检的无缝对接，大大提高了体检的便捷性</div>
              <div class="purchase-title">预防为主</div>

              <div class="purchase-content">智慧健康体检不仅关注疾病的诊断和治疗，更注重疾病的预防和健康管理</div>
              <div class="purchase-title">持续跟踪</div>
              <div class="purchase-content">通过对个人健康数据的长期跟踪和管理，实现对个人健康状况的持续监控和预警</div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 67rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">解决方案</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" style="width: auto" src="../assets/image/example/02-4健康体检.png"/>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">方案特色</div>
            </div>
            <div class="purchase-content-right" >
                <img class="benefits-img" style="height: auto" src="../assets/image/example/02-4健康体检特色.png"/>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.5rem;
    height: 53.2rem;
}
.benefits-img{
    width: 108.4rem;
    height: 71.4rem;
}
</style>
